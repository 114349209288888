import React from 'react'

import { SelectorFilter } from '../../../../filters/SelectorFilter'
import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { ICountriesQuery } from '../../types'
import { getCountries } from '../../queries'
import { preprocessCountries } from '../../utils/filters/prepocess'
import { getLang } from '@/_helpers/getLang'
import { PLATFORM } from '../consts'
import Icons from '@/components/Icons'

const getGqlFilter = (countries: Array<string>, percentage: number) => {
    if (!countries || !percentage) return ''

    return `audience_countries: {country_code_id: {_eq: "${countries[0]}"}, share: {_gte: ${percentage / 100}}}`
}

export const getAudCountryFilter = (fetcher: Fetcher) => {
    return new SelectorFilter({
        platform: PLATFORM,
        locked: (features: string[]) => !features?.includes('audience_country_yt'),
        title: 'filters.countries.fields.aud.label',
        helper: 'filters.countries.fields.aud.text',
        icon: <Icons name="filter--geo" />,
        space: 1,
        autocomplete: true,
        hasPercentage: true,
        featureKey: 'genderAudienceFilter',
        options: async (token: string, locale: string, query?: string) => {
            const client = fetcher.getClient()
            return await client.query<ICountriesQuery>({ query: getCountries(getLang()) }).then((res) => {
                const countries = res.data?.youtube_countriestranslate
                const preprocessed = preprocessCountries(countries)

                if (query) {
                    const searchQuery = query.toLowerCase()
                    return preprocessed.filter((country) => country.label.toLowerCase().includes(searchQuery))
                }

                return preprocessed
            })
        },
        beforeSend: ({ value, percentage }) => {
            return {
                name: 'audience.country',
                type: '',
                value: value,
                gqlFilter: getGqlFilter(value, percentage),
            }
        },
    })
}
