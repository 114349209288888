import React from 'react'
import Icons from '@/components/Icons'
import { getLang } from '@/_helpers/getLang'
import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { SelectorFilter } from '../../../../filters/SelectorFilter'
import { getCountries } from '../../queries'
import { ICountriesQuery } from '../../types'
import { preprocessCountries } from '../../utils/filters/prepocess'
import { toQueryWithOR } from '../../utils/queries/toQueryWithOR'
import { PLATFORM } from '../consts'

const getGqlFilter = (countries: string[]) => {
    if (!countries || countries.length === 0) return ''

    const filterName = 'channel_country'
    const filterId = 'short_name'
    return toQueryWithOR(countries, filterName, filterId)
}

export const getCountryFilter = (fetcher: Fetcher, isMain?: boolean) => {
    return new SelectorFilter({
        platform: PLATFORM,
        title: 'filters.countries.fields.channel.label',
        autocomplete: true,
        helper: 'filters.countries.fields.channel.text',
        icon: <Icons name="filter--geo" />,
        space: 1,
        id: 'countries_yt',
        isMulti: true,
        options: async (token: string, locale: string, query?: string) => {
            const client = fetcher.getClient()
            return await client.query<ICountriesQuery>({ query: getCountries(getLang()) }).then((res) => {
                const countries = res.data?.youtube_countriestranslate
                const preprocessed = preprocessCountries(countries)

                if (query) {
                    const searchQuery = query.toLowerCase()
                    return preprocessed.filter((country) => country.label.toLowerCase().includes(searchQuery))
                }

                return preprocessed
            })
        },
        beforeSend: ({ value }) => {
            return {
                name: 'location.country',
                type: 'array',
                value: value,
                gqlFilter: getGqlFilter(value),
            }
        },
    })
}
